
.p_ss[data-v-1b21df5e]{
		font-size: 12px;
		color: #8A8A8A;
		margin: 0;
		float: left;
		margin-top: 7px;
}
#sa p[data-v-1b21df5e]{
		  float:left;
		  font-size: 12px;
		  color: #C5C5C5;
		  margin: 0;
		  margin-right: 13px;
		  line-height: 30px;
}
#sp p[data-v-1b21df5e]{
		  float:left;
		  font-size: 15px;
		  color: #C5C5C5;
		  margin: 0;
		  margin-right: 13px;
}
.p_boxx[data-v-1b21df5e]{
		 width: 960px;
		 height: 134px;
		 float: left;
		 margin-top: 40px;
}
#imgss[data-v-1b21df5e]{
		 width: 80px;
		 height: 24px;
		 float: left;
		 margin: 41px 27px 0 266px;
}
#btns[data-v-1b21df5e]{
		 width:100%;
     height:180px;
     background:rgba(3,3,38,1);
     float: left;
}
.deng[data-v-1b21df5e]{
		 width:60px;
height:24px;
background:rgba(0,139,255,1);
border-radius:12px;
  float: right;
  margin-top: 15.5px;
   font-size: 14px;
   line-height: 23px;
   text-align: center;
   color: white;
}
.xias[data-v-1b21df5e]{
		width:15px;
height:15px;
float: right;
   margin: 20px 0 0 4px;
}
#fang[data-v-1b21df5e]{
		width:16px;
height:16px;
float:left;
   margin: 19px 0 0 44px;
}
.p_box[data-v-1b21df5e]{
		  width: 33px;
		  height: 100%;
		  font-size: 16px;
		  /*color: #333333;*/
		  float:left;
		  margin-right: 50px;
}
.p_box_s[data-v-1b21df5e]{
		  color: #2D69F6;
}
#imgs[data-v-1b21df5e]{
		 width: 75px;
		 height: 24px;
		 float: left;
		 margin-top: 15px;
		 margin-right: 38px;
}
#box[data-v-1b21df5e]{
		 width: 1409px;
		 height: 55px;
		 /*background:#d4d5d6;*/
		 margin: 0 auto;
		 line-height: 55px;
}
#app[data-v-1b21df5e] {
  font-family: "microsoft yahei";
  width: 100%;
  height: auto;
}
*[data-v-1b21df5e]{
	margin: 0;
	padding: 0;
}

h1[data-v-694cd902], h2[data-v-694cd902] {
  font-weight: normal;
}
ul[data-v-694cd902] {
  list-style-type: none;
  padding: 0;
}
li[data-v-694cd902] {
  display: inline-block;
  margin: 0 10px;
}
a[data-v-694cd902] {
  color: #42b983;
}

#app[data-v-36f4ed7c]{
	/*width: 100%;*/
}



*[data-v-5540915f]{
}
.pasa_s[data-v-5540915f]{
		width:100%;height:auto;
		float:left;
		-webkit-transform: translateY(0px); -ms-transform: translateY(0px); transform: translateY(0px);
}
.pasa[data-v-5540915f]{
		width:100%;height: auto;
		float: left;
		-webkit-transform: translateY(-1735px); -ms-transform: translateY(-1735px); transform: translateY(-1735px);
}
.asa[data-v-5540915f]{
		color: #1B76FF;
}
.x_t3 p[data-v-5540915f]{
		float: left;
		margin: 0;
}
.btn[data-v-5540915f]{
		width:64px;
height:33px;
background:rgba(27,118,255,1);
border-radius:4px;
border: none;
      color: white;
      font-size: 12px;
      float: left;
      margin-left: 12px;
      margin-top: 2px;
}
*[data-v-5540915f]{
		outline:none;
}
#inps[data-v-5540915f]{
		width: 542px;
		height: 100%;
		float: left;
		border: none;
}
.yaos[data-v-5540915f]{
		width:14px;
        height:15px;
        float: left;
        margin: 11px 7px 0 13px;
}
.inputs_f[data-v-5540915f]{
		width:579px;
height:37px;
background:rgba(255,255,255,1);
border:1px solid rgba(204,204,204,1);
border-radius:4px;
float: left;
      font-size: 14px;
      color: #CCCCCC;
}
.inp_b[data-v-5540915f]{
		width: 100%;
		height: 69px;
		padding-top: 16px;
		padding-left: 19px;
}
.img_f[data-v-5540915f]{
		width:264px;
		height: 189px;
		/*background:#C5C5C5;*/
		border-radius: 5px;
}
.img_box[data-v-5540915f]{
		width: 100%;
		height: 215px;
		padding-top: 13px;
		padding-left: 19px;
}
.x_t3[data-v-5540915f]{
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		padding-left: 19px;
}
.x_t2[data-v-5540915f]{
		width: 100%;
		height: 48px;
		line-height: 48px;
		font-size: 14px;
		padding-left: 19px;
}
*[data-v-5540915f]{
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
}
.x_t1[data-v-5540915f]{
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		color: #9FB3C1;
		padding-left: 19px;
		background: #F5F5F5;
}
.tanss[data-v-5540915f]{
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
}
.tanss_s[data-v-5540915f]{
		-webkit-transition:0.5s;
		-o-transition:0.5s;
		transition:0.5s;
}
.tana[data-v-5540915f]{
		width: 100%;
		height: 0;
		/*height:80px;*/
}
.tans[data-v-5540915f]{
		width: 100%;
		height:1780px;
		float: left;
		/*padding-bottom: 20px;*/
		/*background: #D9D9DB;*/
		overflow: hidden;
}
#img_q1[data-v-5540915f]{
		width: 99px;
		height: 31px;
		position: absolute;
		top: 40px;
		left: 190px;
}
#img_q2[data-v-5540915f]{
		width: 126px;
		height: 39px;
		position: absolute;
		top: 161px;
		left: 24px;
}
#img_q3[data-v-5540915f]{
		width: 94px;
		height: 30px;
		position: absolute;
		top: 27px;
		right: 225px;
}
#img_q4[data-v-5540915f]{
		width:123px;
		height: 38px;
		position: absolute;
		top:159px;
		right:35px;
}
.right_xss[data-v-5540915f]{
		width: 46px;
		height:69px;
		position: absolute;
		right: 30px;
		top:378px;
}
.right_xss img[data-v-5540915f]{
		width:46px;
        height:49px;
        opacity:0.4;
		position: relative;
		-webkit-animation: myfirst-data-v-5540915f 0.9s linear 2s infinite alternate;
}
.right_tss[data-v-5540915f]{
		width: 31px;
		height:53px;
		position: absolute;
		right: 269px;
		top:77px;
}
.right_tss img[data-v-5540915f]{
		width:31px;
height:33px;
opacity:0.4;
		position: relative;
		-webkit-animation: myfirst-data-v-5540915f 1.2s linear 2s infinite alternate;
}
.right_bss[data-v-5540915f]{
		width: 58px;
		height:84px;
		position: absolute;
		right: 395px;
		top:314px;
}
.right_bss img[data-v-5540915f]{
		height: 64px;
		width: 58px;
		position: relative;
		-webkit-animation: myfirst-data-v-5540915f 1.3s linear 2s infinite alternate;
}
.left_bss[data-v-5540915f]{
		width: 120px;
		height:110px;
		position: absolute;
		left: 127px;
		top: 325px;
}
.left_bss img[data-v-5540915f]{
		height: 98px;
		width: 110px;
		position: relative;
		-webkit-animation: myfirst-data-v-5540915f 1s linear 2s infinite alternate;
}
@-webkit-keyframes myfirst-data-v-5540915f 
         {
0%  {-webkit-transform: translateY(10px); transform: translateY(10px);
}
100% {-webkit-transform: translateY(0px); transform: translateY(0px);
}
}
.left_ts[data-v-5540915f]{
		width: 67px;
		height: 80px;
		position: absolute;
		left: 319px;
		top: 50px;
}
.left_ts img[data-v-5540915f]{
		height: 55px;
		width: 67px;
		position: relative;
		-webkit-animation: myfirst-data-v-5540915f 1.1s linear 2s infinite alternate;
}
.qiu_x[data-v-5540915f]{
width: 94%;
position: absolute;
left: 2.5%;
top: 0;
z-index:0;
-webkit-transition-property: -webkit-transform;  

-webkit-transition-duration: 200s;  

-moz-transition-property: -moz-transform;  

-moz-transition-duration: 200s;  

-webkit-animation: rotate-data-v-5540915f 400s linear infinite;  

animation: rotate-data-v-5540915f 400s linear infinite;

-webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
}
@-webkit-keyframes rotate-data-v-5540915f{
from{-webkit-transform: rotate(0deg)
}
to{-webkit-transform: rotate(360deg)
}
}
@keyframes rotate-data-v-5540915f{
from{-webkit-transform: rotate(0deg);transform: rotate(0deg)
}
to{-webkit-transform: rotate(359deg);transform: rotate(359deg)
}
}
.gous[data-v-5540915f]{
		width:13px;
       height:10px;
}
.pas[data-v-5540915f]{
		font-size:12px;
font-family:MicrosoftYaHei;
color:rgba(27,118,255,1);
line-height:48px;
}
.boxxsa[data-v-5540915f]{
		width: 183px;
		/*height: auto;*/
		/*margin: 0 auto;*/
		font-size: 14px;
		color: #666666;
		line-height: 21px;
}
.sans[data-v-5540915f]{
		width: 15px;
		height: 15px;
		position:absolute;
		left:-12px;
		top: 8px;
}
.er[data-v-5540915f]{
	  width:100%;
      height:100%;
      float: left;
}
.tan_b[data-v-5540915f]{
		 width:220px;
       height:auto;
       background:rgba(255,255,255,1);
        position: absolute;
        top: 24px;
        left:64px;
        /*background: red;*/
        z-index: 2; -webkit-box-shadow:0px 0px 20px #D9D9DB; box-shadow:0px 0px 20px #D9D9DB;
	    display: none;
	    padding-top: 15px;
	    padding-left: 18px;
	    -webkit-box-sizing: border-box;
	            box-sizing: border-box;
}
.ers:hover+.tan_b[data-v-5540915f]{
		display: block;
}
	/*.bans:hover .er{
		display: block;
	}*/
.bans[data-v-5540915f]{
		width: 29px;margin-right: 76px;position:relative;height: 100%;float: left;
}
.ers[data-v-5540915f]{
	width:14px;
    height:14px;
    /*float: left;*/
    /*margin-bottom: 30px;*/
   /*background: black;*/
   position: absolute;
   right: -22px;
   top: 33px;
   /*margin-top: 33px;*/
   /*margin-left:32px ;*/
   /*display: none;*/
  z-index: 2;
}
*[data-v-5540915f]{
		font-family: "microsoft yahei";
}
.xi[data-v-5540915f]{
		width:54px;
height:33px;
background:rgba(27,118,255,1);
    float: left;
    margin-top: 24px;
    text-align: center;
    line-height: 33px;
    color: white;
    font-size: 14px;
}
.shi[data-v-5540915f]{
		position: absolute;
		bottom:-22px;
		left: 0;
}
.box_sxs_p[data-v-5540915f]{
 	
 	overflow: hidden;
    -o-text-overflow:ellipsis;
       text-overflow:ellipsis;
    white-space: nowrap;
}
.box_sxs p[data-v-5540915f]{
 	float: left;
 	margin: 0;
}
.box_sxs[data-v-5540915f]{
 	width: 100%;
 	/*height: 80px;*/
 	border-top: 1px solid #F0F0F0;
 	font-size: 14px;
 	line-height: 80px;
 	float: left;
 	/*overflow: hidden;*/
 	/*-webkit-transform-origin : 100% 0 0;*/
 	 -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
}
.box_sxsa[data-v-5540915f]{
 	/*-webkit-transform:scaleY(10)*/
}
.box_ff[data-v-5540915f]{
 	width: 100%;
 	height: 80px;
}
.boxa_t p[data-v-5540915f]{
	 float: left;
	 margin: 0;
}
.boxa_t[data-v-5540915f]{
		width: 100%;
		height: 60px;
		line-height: 60px;
		font-size: 14px;
		color: #9FB3C1;
}
.box_sx[data-v-5540915f]{
	   width:1400px;
       float: left;
       background:rgba(255,255,255,1); -webkit-box-shadow:0px 0px 20px #D9D9DB; box-shadow:0px 0px 20px #D9D9DB;
}
.pssx[data-v-5540915f]{
		font-size:26px;
font-family:MicrosoftYaHei;
color:rgba(51,51,51,1);
float: left;
margin: 0;
margin-top:35px;
}
.imgz[data-v-5540915f]{
		width:23px;
height:24px;
float: left;
       margin: 42px 14px 0 7px;
}
.box_top[data-v-5540915f]{
		width: 100%;
		height: 86px;
}
.right_b[data-v-5540915f]{
		position: absolute;
		right: 28px;
		bottom: 138px;
}
.as[data-v-5540915f]{
		font-size: 14px;
}
.right_t[data-v-5540915f]{
		position: absolute;
		right: 215px;
		bottom: 274px;
}
.left_bs[data-v-5540915f]{
		position: absolute;
		left: 27px;
		top: 106px;
}
.pxss[data-v-5540915f]{
		font-size: 20px;
		color: #2D69F6;
		font-weight: 600;
}
.pxs[data-v-5540915f]{
		font-size: 16px;
		color: white;
}
.left_b[data-v-5540915f]{
		/*height: 45px;*/
		padding: 0 5px;
		/*background: red;*/
		float: left;
}
.px[data-v-5540915f]{
		color: #2D69F6;
}
.left_t[data-v-5540915f]{
		width: 100px;
		height: 21px;
		position: absolute;
		top: 14px;
		left: 188px;
		line-height: 21px;
}
.left_t p[data-v-5540915f]{
		/*padding: 0 5px;*/
		float: left;
		color: white;
		font-size: 16px;
}
.sous[data-v-5540915f]{
		width:81px;
height:42px;
background:rgba(27,118,255,1);
float: left;
    font-size: 16px;
    color: white;
    line-height: 42px;
    text-align: center;
}
.img_s[data-v-5540915f]{
		width:19px;
        height:19px;
        margin: 12px 8px 0 13px;
        float: left;
}
#inp[data-v-5540915f]{
		width:520px;
height:42px;
background:rgba(255,255,255,1);
float: left;
      font-size: 14px;
      outline:none;
      border: none;
}
.inp_box[data-v-5540915f]{
		 width: 641px;
		 height: 42px;
		 background:white;
		 margin: 0 auto;
		 margin-top: 18px;
}
#ps[data-v-5540915f]{
		 font-size:18px;
font-family:MicrosoftYaHei;
color:rgba(255,255,255,1);
line-height:48px;
}
#px[data-v-5540915f]{
		font-size: 40px;
		color: white;
		font-weight: 600;
		float: left;
		width: 100%;
		margin-top: 68px;
}
.z_box[data-v-5540915f]{
		width:988px;
        height:291px;
     /*background: cornflowerblue;*/
      /*border: 1px solid #42B983;*/
     margin: 0 auto;
      position: relative;
      text-align: center;
      /*padding-top: 68px;*/
}
.tops[data-v-5540915f]{
	   width:100%;
       height:400px;
       background: url(../../static/img/bei_j.e242612.png);
       
       background-size:100% 100%;
       background-repeat:no-repeat;
       
       padding-top: 109px;
       -webkit-box-sizing: border-box;
               box-sizing: border-box;
       position: relative;
       overflow:hidden;
}
	
	
































